import React from "react";
import { getWhiteLabelPartnerName, getWhiteLabelCopyrightName } from "util/whitelabel";
import moment from "moment-timezone";

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const priceList =
{
    "hipaa": 100,
    "cisl12": 100,
    "additional_cloud_account": 50,
    "additional_checks": 50,
    "lite": 100,
    "basic": 200,
    "pro": 333,
    "cloud_account": 50,
    "master_account": 200
};

export const yearly_percent_off = 10;

export const planDetails = {
    "resources_tier1": "Monitoring",
    "frequency_tier1": "Frequency",
    "account_support_tier1": "Cloud Account",
    "support_tier1": "Support Response",
    "cis_l1": "Compliance Pack",
    "cis": "CIS Level 1 & 2",
    "cislevel1": "CIS Level 1",
    "cislevel2": "CIS Level 2",
    "basic": "Basic",
    "lite": "Lite",
    "pro": "Pro",
    "enterprise": "Enterprise",
    "hipaa": "HIPAA Readiness",
    "nist171": "NIST 800-171",
    "nist853": "NIST 800-53",
    "cloud_account": "Cloud Accounts",
    "resources_tier0": "Monitoring",
    "pentest": "WebApp Scanner"
};

export const priorityMap = {
    "critical": "Critical",
    "medium": "Medium",
    "low": "Low",
    "high": "High"
};
export const tierMap = {
    "lite": "Lite",
    "basic": "Basic",
    "pro": "Pro",
    "free": "Free",
    "enterprise": "Enterprise",

};

export const priorityColorBackground = {
    "critical": "red",
    "high": "orange",
    "medium": "yellow",
    "low": "forestgreen"
};

export const policyNameMap = {
    "cislevel1": "CIS Level 1",
    "cislevel2": "CIS Level 2",
    "hipaa": "HIPAA Readiness",
    "gdpr": "GDPR",
    "security": "Security",
    "pentest": "WebApp Scanner",
    "soc2": "SOC2 Readiness",
    "soc1": "SOC1 Readiness",
    "iso27001": "ISO Readiness",
    "awswellarch": "AWS Well Architected",
    "nist171": "NIST 800-171 Readiness",
    "nist853": "NIST 800-53 Readiness"
};

export const policyCheckTimingMap = {
    "security": "30 - 60 ",
    "costsaving": "5",
    "cislevel1": "15",
    "cislevel2": "15",
    "hipaa": "15",
    "soc2": "30 - 60 ",
    "iso27001": "60",
    "nist171": "60",
    "nist853": "60",
    "awswellarch": "30 - 60 "

};

export const serviceTypeMap = {
    "aws": {
        "acm": "AWS CertificateManager",
        "awslambda": "Lambda",
        "cloudformation": "CloudFormation",
        "cloudwatch": "AmazonCloudWatch",
        "cloudtrail": "AWS CloudTrail",
        "config": "AWS Config",
        "directconnect": "AWS DirectConnect",
        "ec2": "EC2 - Other",
        "efs": "Amazon Elastic File System",
        "elasticache": "Amazon ElastiCache",
        "elb": "Amazon Elastic Load Balancing",
        "elbv2": "Amazon Elastic Load Balancing",
        "emr": "Amazon EMR",
        "iam": "IAM",
        "rds": "RDS",
        "route53": "Amazon Route 53",
        "s3": "S3Bucket",
        "ses": "Amazon Simple Email Service",
        "sns": "SNS",
        "sqs": "SQS",
        "vpc": "VPC",
        "kms": "AWS_KMS",
        "lightsail": "AWS_LightSail",
        "secretsmanager": "AWS_Secrets_Manager",
        "dynamodb": "Amazon_DynamoDB",
        "redshift": "Amazon_RedShift"
    },
    "azure": {
        "aad": "Azure Active Directory",
        "appservice": "App Service",
        "keyvault": "Key Vault",
        "monitor": "Monitor",
        "network": "Network",
        "rbac": "Role Based Access Control",
        "securitycenter": "Security Center",
        "sqldatabase": "SQL Database",
        "storageaccounts": "Storage Accounts",
        "virtualmachines": "Virtual Machines"
    }
};

export const awsRegionMap = {
    "us-east-1": { name: "US East (N. Virginia)", longitude: -81.66, latitude: 38.13 },
    "us-east-2": { name: "US East (Ohio)", longitude: -84.91, latitude: 40.34 },
    "us-west-1": { name: "US West (N. California)", longitude: -122.38, latitude: 38.88 },
    "us-west-2": { name: "US West (Oregon)", longitude: -122.82, latitude: 44.12 },
    "ca-central-1": { name: "Canada (Central)", longitude: -79.52, latitude: 43.64 },
    "eu-west-1": { name: "EU (Ireland)", longitude: -10.34, latitude: 53.36 },
    "eu-central-1": { name: "EU (Frankfurt)", longitude: 8.49, latitude: 50.12 },
    "eu-west-2": { name: "EU (London)", longitude: -0.24, latitude: 51.52 },
    "eu-west-3": { name: "EU (Paris)", longitude: 2.27, latitude: 48.86 },
    "eu-north-1": { name: "EU (Stockholm)", longitude: 17.84, latitude: 59.32 },
    "ap-northeast-1": { name: "Asia Pacific (Tokyo)", longitude: 139.60, latitude: 35.66 },
    "ap-northeast-2": { name: "Asia Pacific (Seoul)", longitude: 126.84, latitude: 37.56 },
    "ap-southeast-1": { name: "Asia Pacific (Singapore)", longitude: 103.8, latitude: 1.37 },
    "ap-southeast-2": { name: "Asia Pacific (Sydney)", longitude: 149.65, latitude: -33.41 },
    "ap-south-1": { name: "Asia Pacific (Mumbai)", longitude: 72.88, latitude: 19.08 },
    "ap-east-1": { name: "Asia Pacific (Hong Kong)", longitude: 114.1694, latitude: 22.3193 },
    "sa-east-1": { name: "South America (São Paulo)", longitude: -46.636962, latitude: -23.522441 },
    "global": { name: "Global", longitude: 0, latitude: 0 },
    "me-south-1": { name: "Middle East (Bahrain)", longitude: 50.33, latitude: 26.00 },
    "af-south-1": { name: "Africa (Cape Town)", longitude: 18.423300, latitude: -33.918861 },
    "eu-south-1": { name: "Europe (Milan)", longitude: 9.188540, latitude: 45.464664 },
    "ap-northeast-3": { name: "Asia Pacific (Osaka-Local)", longitude: 135.497009, latitude: 34.669529 }
};

export function lastScanned(check_run_date) {

    var n = new Date();
    var utc = n.getTime() + n.getTimezoneOffset() * 60000;
    var check_run_date_date = Date.parse(check_run_date && check_run_date.replace("T", ' ').replace("Z", ""));

    let diffInMilliSeconds = Math.abs((utc - check_run_date_date) / 1000);
    const days = Math.floor(diffInMilliSeconds / (3600 * 24));
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    const hours_display = hours + days * 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    if (!isNaN(hours)) {
        if (hours === 0) {

            return <span >  Scan completed {minutes} minutes ago</span>
        }
        else return <span >  Scan completed {hours_display} hours ago</span>
    }
    else return null;
}

export function lastScannedFromArray(check_run_dates) {
    var array = [];
    check_run_dates && check_run_dates.map(check_run_date => {
        var n = new Date();
        var utc = n.getTime() + n.getTimezoneOffset() * 60000;
        let diffInMilliSeconds = Math.abs((utc - Date.parse(check_run_date)) / 1000);

        array.push(diffInMilliSeconds);
        return check_run_date;
    })

    var minimumDiff = Math.min(...array);
    const days = Math.floor(minimumDiff / (3600 * 24));
    const hours = Math.floor(minimumDiff / 3600) % 24;
    const hours_display = hours + days * 24;
    minimumDiff -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(minimumDiff / 60) % 60;
    minimumDiff -= minutes * 60;


    if (!isNaN(hours)) {
        if (hours === 0) {

            return <span className="gx-text-grey gx-text-align-right">  Last scanned {minutes} minutes ago</span>
        }
        else return <span className="gx-text-grey gx-text-align-right">  Last scanned {hours_display} hours ago</span>
    }
    else return null;
}

export function formatDate(dateString) {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = moment(dateString).tz(userTimezone);
    const timeZoneabbr = date.zoneAbbr();
    const formattedDate = date.format("MMMM DD, YYYY [at] hh:mm A");
    return `${formattedDate} ${timeZoneabbr}`;
}

export const checkMap = {
    'AZ:SERVICE': 'check513',
    'AZ:REGION': 'check514',
    'AZ:OPERATION': 'check515',
    'AZ:USAGE_TYPE': 'check516',
    'AZ:INSTANCE_TYPE': 'check517',
    'AZ:PLATFORM': 'check518',
    'AZ:PURCHASE_TYPE': 'check519',
    'AZ:TENANCY': 'check520',
    'AZ:DEPLOYMENT_OPTION': 'check521',
    'AZ:DATABASE_ENGINE': 'check522',
    'AZ:CACHE_ENGINE': 'check523',
    'AZ:RECORD_TYPE': 'check524',
    'AZ:LEGAL_ENTITY_NAME': 'check525',
    'AZ:INSTANCE_TYPE_FAMILY': 'check526',
    'AZ:BILLING_ENTITY': 'check527',
    'AZ:RESERVATION_ID': 'check528',
    'AZ:SAVINGS_PLANS_TYPE': 'check529',
    'AZ:SAVINGS_PLAN_ARN': 'check530',
    'AZ:OPERATING_SYSTEM': 'check531',
    'OPERATION:AZ': 'check532',
    'OPERATION:SERVICE': 'check533',
    'OPERATION:REGION': 'check534',
    'OPERATION:USAGE_TYPE': 'check535',
    'OPERATION:INSTANCE_TYPE': 'check536',
    'OPERATION:PLATFORM': 'check537',
    'OPERATION:PURCHASE_TYPE': 'check538',
    'OPERATION:TENANCY': 'check539',
    'OPERATION:DEPLOYMENT_OPTION': 'check540',
    'OPERATION:DATABASE_ENGINE': 'check541',
    'OPERATION:CACHE_ENGINE': 'check542',
    'OPERATION:RECORD_TYPE': 'check543',
    'OPERATION:LEGAL_ENTITY_NAME': 'check544',
    'OPERATION:INSTANCE_TYPE_FAMILY': 'check545',
    'OPERATION:BILLING_ENTITY': 'check546',
    'OPERATION:RESERVATION_ID': 'check547',
    'OPERATION:SAVINGS_PLANS_TYPE': 'check548',
    'OPERATION:SAVINGS_PLAN_ARN': 'check549',
    'OPERATION:OPERATING_SYSTEM': 'check550',
    'LINKED_ACCOUNT': 'check81',
    'SERVICE:LINKED_ACCOUNT': 'check83',
    'REGION:LINKED_ACCOUNT': 'check84',
    'OPERATION:LINKED_ACCOUNT': 'check85',
    'USAGE_TYPE:LINKED_ACCOUNT': 'check86',
    'AZ:LINKED_ACCOUNT': 'check87',
    'INSTANCE_TYPE:LINKED_ACCOUNT': 'check88',
    'PLATFORM:LINKED_ACCOUNT': 'check89',
    'PURCHASE_TYPE:LINKED_ACCOUNT': 'check90',
    'TENANCY:LINKED_ACCOUNT': 'check91',
    'DEPLOYMENT_OPTION:LINKED_ACCOUNT': 'check92',
    'DATABASE_ENGINE:LINKED_ACCOUNT': 'check93',
    'CACHE_ENGINE:LINKED_ACCOUNT': 'check94',
    'RECORD_TYPE:LINKED_ACCOUNT': 'check95',
    'LEGAL_ENTITY_NAME:LINKED_ACCOUNT': 'check96',
    'INSTANCE_TYPE_FAMILY:LINKED_ACCOUNT': 'check97',
    'BILLING_ENTITY:LINKED_ACCOUNT': 'check98',
    'RESERVATION_ID:LINKED_ACCOUNT': 'check99',
    'SAVINGS_PLANS_TYPE:LINKED_ACCOUNT': 'check100',
    'SAVINGS_PLAN_ARN:LINKED_ACCOUNT': 'check511',
    'OPERATING_SYSTEM:LINKED_ACCOUNT': 'check512',
};

export const notifyPrefMap = {
    "security": "Security",
    "cost": "Cost",
    "cis": "CIS",
    "hipaa": "HIPAA Readiness",
    "resources": "Assets"
};

export const notifyServiceMap = {
    "slack": "Slack",
    "sns": "SNS",
    "twilio": "Twilio",
    "sendgrid": "SendGrid",
    "msteams": "MS Teams",
    "ifttt": "IFTTT"
};

export const penScanTypeMap = {
    "baseline": "Baseline",
    "full": "Full",

}
export const penTestRiskNamesMap = {
    "0": "Informational",
    "1": "Low",
    "2": "Medium",
    "3": "High"
}
export const penTestRiskLevelColorMap = {
    "High (High)": "#f94c56",
    "High (Medium)": "#f94c56",
    "High (Low)": "#f94c56",
    "Medium (High)": "#fa8c16",
    "Medium (Medium)": "#fa8c16",
    "Medium (Low)": "#fa8c16",
    "Low (High)": "#fadb14",
    "Low (Medium)": "#fadb14",
    "Low (Low)": "#fadb14",
    "Informational (High)": "#038FDE",
    "Informational (Medium)": "#038FDE",
    "Informational (Low)": "#038FDE",
}

export const severityColorMap = {
    "high": "#f94c56",
    "low": "#fadb14",
    "medium": "#fa8c16",
    "informational": "#038FDE",
    "0": "#038FDE",
    "1": "#fadb14",
    "2": "#fa8c16",
    "3": "#f94c56"
}

export const costRecMap = {
    "right-sizing": "Right - Sizing",
    "storage": "Storage",
    "compute": "Compute",
    "idle": "Idle",
    "network": "Network"
}

export const getCopyright = () => `© ${getWhiteLabelCopyrightName()}`;

export const lockedAddonData = {
    cislevel1: {
        title: "CIS Level 1 and 2 Checks",
        desc: "CIS Benchmarks provide the baseline configurations to ensure compliance with industry-agreed cybersecurity standards. It is a framework specializes in custom configuration of Cloud IT services and products. Organizations can use the Benchmarks and frameworks to improve cybersecurity posture."
    },
    cislevel2: {
        title: "CIS Level 1 and 2 Checks",
        desc: "CIS Benchmarks provide the baseline configurations to ensure compliance with industry-agreed cybersecurity standards. It is a framework specializes in custom configuration of Cloud IT services and products. Organizations can use the Benchmarks and frameworks to improve cybersecurity posture."
    },
    soc1: {
        title: "SOC1 Checks",
        desc: "Report on Management's Description of a Service Organization's System and the Suitability of the Design and Operating Effectiveness of Controls. The Type II report addresses the design and testing of the controls over a period of time, It also describes the testing performed and the results."
    },
    soc2: {
        title: "SOC2 Checks",
        desc: "Certification that ensures your service providers securely manage your data to protect the interests of your organization and the privacy of its clients. This is essential when two companies are partnering or acquisition, mostly for SaaS based products."
    },
    hipaa: {
        title: "HIPAA Readiness Checks",
        desc: "Regulation that provides guidelines to implement multiple safeguards to protect sensitive personal identifiable information and health related documents."
    },
    pentest: {
        title: "WebApp Scanner",
        desc: 'Web Application OWASP Scanner is an automated tool that scan web applications, "from outside to inside", to look for the OWASP top 10 security vulnerabilities such as Cross-site scripting, SQL Injection, Command Injection, Path Traversal and insecure server configuration, just to name a few.'
    },
    iso27001: {
        title: "ISO27001 Readiness Checks",
        desc: "Certification that demonstrates your organization has invested in the people, processes, and technology including tools and systems to protect your organization's data and provides an independent, expert assessment of whether your data is sufficiently protected. This is majorly used for companies that are in Hybrid model."
    },
    awswellarch: {
        title: "AWS Well Architected Checks",
        desc: "Framework built on 5 core pillars of cloud based IT, when implemented enabled a way for businesses to consistently measure your architectures against best practices and identify areas for improvement."
    },
    nist171: {
        title: "NIST 800-171",
        desc: `NIST 800-171, also known as "Protecting Controlled Unclassified Information in Nonfederal Systems and Organizations" is a comprehensive set of security guidelines published by the National Institute of Standards and Technology (NIST) to safeguard sensitive information shared with or stored by nonfederal organizations. This publication establishes a baseline of security requirements that companies must adhere to when handling controlled unclassified information (CUI) on their systems.`
    },
    nist853: {
        title: "NIST 800-53",
        desc: `NIST 800-53, also known as "Security and Privacy Controls for Information Systems and Organizations" is a comprehensive framework designed by the National Institute of Standards and Technology (NIST) to bolster the security of federal information systems and the organizations operating them. This framework encompasses a comprehensive set of security controls and guidelines that federal agencies and their associated contractors are required to put in place to safeguard sensitive information and uphold the integrity, confidentiality, and availability of data within their systems and networks. It serves as an essential resource for establishing and maintaining robust cybersecurity practices across the federal government and its partner organizations.`
    }
}

export const constantMessages = {
    "reportSuccess": {
        type: "success",
        msg: "Report has been downloaded Successfully!",
        length: 10
    },
    "reportError": {
        type: "error",
        msg: "Unable to generate the report, please try again.",
        length: 10
    },
    "reportLoading": {
        type: "info",
        msg: "Report is loading",
        length: 3
    },
    "success": {
        type: "success",
        msg: "Success!",
        length: 3
    },
    "saved": {
        type: "success",
        msg: "Saved!",
        length: 2
    },
    "addonsChangeSuccess": {
        type: "success",
        msg: "Your request for a change in subscription was successful!",
        length: 10
    },
    "cloudAccountChecksVerified": {
        type: "success",
        msg: "Checks are verified!",
        length: 2
    },
    "cloudAccountEditSuccess": {
        type: "success",
        msg: "Cloud Account Updated Successfully",
        length: 5
    },
    "cloudAccountDeleteSuccess": {
        type: "success",
        msg: "Cloud Account Deleted Successfully!",
        length: 10
    },
    "initiateCheckAdding": {
        type: "success",
        msg: "Adding a cloud account incurs tier and addon payments",
        length: 10
    },
    "costReportSuccess": {
        type: "success",
        msg: "Cost Recommendations Report has been downloaded Successfully!",
        length: 10
    },
    "questionnaireReportSuccess": {
        type: "success",
        msg: "Questionnaire Report has been downloaded Successfully!",
        length: 10
    },
    "subuserRemoveSuccess": {
        type: "success",
        msg: "User Removed Successfully!",
        length: 2
    },
    "subuserResendInvite": {
        type: "success",
        msg: "Resending the User Account Invitation...",
        length: 2
    },
    "complianceReportSuccess": {
        type: "success",
        msg: "Compliance Report has been downloaded Successfully!",
        length: 10
    },
    "exceptionRemoveSuccess": {
        type: "success",
        msg: "Exception removed Successfully!",
        length: 2
    },
    "exceptionAddSuccess": {
        type: "success",
        msg: "Exception added Successfully!",
        length: 2
    },
    "exceptionUpdateSuccess": {
        type: "success",
        msg: "Exception updated successfully!",
        length: 2
    },
    "devicesEditSuccess": {
        type: "success",
        msg: "Device Edited Successfully",
        length: 2
    },
    "devicePersonSuccess": {
        type: "success",
        msg: "Person Device Association added Successfully",
        length: 2
    },
    "personAddSuccess": {
        type: "success",
        msg: "Person added as Admin Successfully",
        length: 2
    },
    "deviceAddSuccess": {
        type: "success",
        msg: "Device added Successfully",
        length: 2
    },
    "personnelPersonEditSuccess": {
        type: "success",
        msg: "Person Edited Successfully",
        length: 2
    },
    "personnelPersonAddSuccess": {
        type: "success",
        msg: "Person added Successfully",
        length: 2
    },
    "personnelEmailExists": {
        type: "error",
        msg: "Email is already associated with another person",
        length: 10
    },
    "personnelSummaryError": {
        type: "error",
        msg: "Error while Retreiving Personnel Data",
        length: 5
    },
    "personnelRoleDataError": {
        type: "error",
        msg: "Error while Retreiving Role Data",
        length: 5
    },
    "personnelRoleSuccess": {
        type: "success",
        msg: "Person Role Association added Successfully",
        length: 2
    },

    "personnelDeviceDeleteSuccess": {
        type: "success",
        msg: " Deleted Person Device Association",
        length: 5
    },
    "personnelRoleDeleteSuccess": {
        type: "success",
        msg: " Deleted Person Role Association",
        length: 5
    },
    "personnelDeviceDeleteError": {
        type: "error",
        msg: "Error while Deleting Person Device Association",
        length: 5
    },
    "deviceDeleteSuccess": {
        type: "success",
        msg: "Successfuly Deleted Device",
        length: 5
    },
    "roleDeleteSuccess": {
        type: "success",
        msg: "Role Deleted Successfully",
        length: 2
    },
    "roleEditSuccess": {
        type: "success",
        msg: "Role Updated Successfully",
        length: 2
    },
    "roleNameExists": {
        type: "error",
        msg: "Role Name Already Exists",
        length: 5
    },
    "roleAddSuccess": {
        type: "success",
        msg: "Role Added Successfully",
        length: 2
    },
    "vendorEditSuccess": {
        type: "success",
        msg: "Vendor Edited Successfully",
        length: 2
    },
    "vendorDeleteSuccess": {
        type: "success",
        msg: "Vendor Deleted Successfully ",
        length: 2
    },
    "vendorAddSuccess": {
        type: "success",
        msg: "Vendor Added Successfully",
        length: 2
    },
    "scannerReportSuccess": {
        type: "success",
        msg: "WebApp Scanner Report has been downloaded Successfully!",
        length: 10
    },
    "webappEditSuccess": {
        type: "success",
        msg: "WebApp edited successfully",
        length: 2
    },
    "webappScanInitInfo": {
        type: "info",
        msg: "Scan has been initiated successfully! Please check details for more information.",
        length: 10
    },
    "urlEditSuccess": {
        type: "success",
        msg: "URL edited successfully",
        length: 2
    },
    "themeUpdateSuccess": {
        type: "success",
        msg: "Theme updated successfully",
        length: 2
    },
    "passwordResetSuccess": {
        type: "success",
        msg: "Password has been reset successfully! Please proceed to login",
        length: 2
    },
    "cloudAccountInfoError": {
        type: "error",
        msg: "Please complete the form to continue",
        length: 10
    },
    "cloudAccountCreateError": {
        type: "error",
        msg: `Could not create cloud account. Please check if the account is already in use within ${getWhiteLabelPartnerName()}.`,
        length: 10
    },
    "cloudAccountEditError": {
        type: "error",
        msg: "Error while updating Cloud Account",
        length: 10
    },
    "cloudAccountIdError": {
        type: "error",
        msg: "Cloud Role ID is required to proceed..",
        length: 10
    },
    "cloudAccountInitError": {
        type: "error",
        msg: "Not able to initiate the checks!",
        length: 2
    },
    "cloudAccountDeleteError": {
        type: "error",
        msg: "Not able to delete the account",
        length: 10
    },
    "initiateCheckSecurityError": {
        type: "error",
        msg: "Not able to initiate the Security check!",
        length: 2
    },
    "initiateCheckError": {
        type: "error",
        msg: "Not able to initiate the checks!",
        length: 2
    },
    "initiateCheckAddonsError": {
        type: "error",
        msg: "Not able to get the addons!",
        length: 2
    },
    "notificationError": {
        type: "error",
        msg: "Error triggering the notification",
        length: 2
    },
    "settingsPasswordError": {
        type: "error",
        msg: "Invalid Password.",
        length: 2
    },
    "subUserInviteError": {
        type: "error",
        msg: "Not able to resend invitation to the User",
        length: 2
    },
    "subUserRemoveError": {
        type: "error",
        msg: "Not able to remove the User",
        length: 2
    },
    "cloudResourcesDataError": {
        type: "error",
        msg: "Not able to get data from the server",
        length: 2
    },
    "complianceReportInfo": {
        type: "info",
        msg: "Violations Report is being generated on the server and it will be emailed when its ready.",
        length: 2
    },
    "pleaseCheckFieldsError": {
        type: "error",
        msg: "Please check the required fields..",
        length: 2
    },
    "securityDetailUpdateError": {
        type: "error",
        msg: "Error updating the Exception",
        length: 2
    },
    "devicePersonDeleteSuccess": {
        type: "success",
        msg: "Person Device Association deleted Successfully",
        length: 10
    },
    "personnelDeleteSuccess": {
        type: "success",
        msg: "Person removed successfully",
        length: 5
    },
    "themeUpdateError": {
        type: "error",
        msg: "Failed to update theme",
        length: 2
    },
    "themeResetError": {
        type: "error",
        msg: "Failed to reset theme",
        length: 2
    },
    "menuItemClickInfo": {
        type: "info",
        msg: "Click on menu item.",
        length: 2
    }
}


