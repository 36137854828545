import React, { Component } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { getMsgData } from "../util/functions";
import { handleGeneralErrorMessage } from "../util/functions";
import IntlMessages from "util/IntlMessages";
import { Redirect} from "react-router-dom";
import axios from "axios";
import Background from "assets/images/mission2.png";
import { getWhiteLabelPartnerName, getWhiteLabelLogoUrl } from "util/whitelabel";

const FormItem = Form.Item;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToSignin: false,
      disabled: true,
      loading: false,
      token: ""
    };
  }

  handleOnFocus = () => {
    if (this.state.disabled) {
      this.setState({ disabled: false });
    }
  };

  async componentDidMount() {
    try {
  
    const pathSegments = window.location.pathname.split("/");
    const token = pathSegments[pathSegments.length - 1]; 
    this.setState({ token });
  
    } catch (err) {
      handleGeneralErrorMessage(err);
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err && values) {
        this.setState({ loading: true })
        if(values.password === values.confirm ){
          try {
            let result = await axios({
              method: "post",
              headers: {
                "content-type": "application/json"
              },
              url: "/backend/account/passwordReset",
              data: {
                token : this.state.token,
                password: values.password
              }
            });
  
            if (result && result.data && result.data.status === "verified") {
              getMsgData("passwordResetSuccess");
              this.setState({ redirectToSignin: true });
            }
            this.setState({ loading: false })
          } catch (error) {
            this.setState({ loading: false })
            handleGeneralErrorMessage(error);
          }
        }
        else{
          handleGeneralErrorMessage("The password did not match.");
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {  redirectToSignin, disabled } = this.state;
    if (redirectToSignin === true) {
      return <Redirect to="/signin" />;
    } else
      return (
        <div
          className="gx-app-login-wrap font-poppins"
          style={{
            backgroundImage: `url(/clouds-sky.png)`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            animation: "float 6s ease-in-out infinite"
          }}
        >
          <img
            src="/ic-zen-dude.svg"
            className="max-sm:hidden"
            alt="Zen Mode"
            style={{
              position: "absolute",
              left: "7%",
              top: "50%",
              transform: "translateY(-50%)",
              width: "14%",
              zIndex: 10
            }}
          />
          <img
            src="https://www.icompaas.com/static/i/form-icon.svg"
            className="max-sm:hidden"
            alt="Form Icon"
            style={{
              position: "absolute",
              top: "3%",
              left: "6%",
              width: "4%",
              zIndex: 2
            }}
          />
          <img
            src="https://images.icompaas.com/i/lock-badge-blue.svg"
            className="max-sm:hidden"
            alt="Lock Badge"
            style={{
              position: "absolute",
              top: "30%",
              right: "5%",
              width: "5%",
              zIndex: 2
            }}
          />
          <img
            src="https://images.icompaas.com/i/dollar-circle-blue.svg"
            className="max-sm:hidden"
            alt="Dollar Circle"
            style={{
              position: "absolute",
              bottom: "2%",
              left: "0%",
              width: "5%",
              zIndex: 2
            }}
          />
          <div className="gx-app-login-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           
              <div className="gx-login-content font-poppins">
                <div className="gx-login-header">
                  <img
                    src={getWhiteLabelLogoUrl()}
                    alt={getWhiteLabelPartnerName()}
                    title={getWhiteLabelPartnerName()}
                  />
                </div>
                <div className="gx-mb-4">
                  <h2>Reset Password</h2>
                  <p>
                    <IntlMessages id="appModule.enterPasswordReset" />
                  </p>
                </div>

                <Form
                  onSubmit={this.handleSubmit}
                  className="gx-login-form gx-form-row0"
                >
                  <FormItem>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your password!"
                        },
                        {
                          validator: this.validateToNextPassword
                        }
                      ]
                    })(
                      <Input
                        type="password"
                        readOnly={disabled}
                        onFocus={this.handleOnFocus}
                        placeholder="New Password"
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator("confirm", {
                      rules: [
                        {
                          required: true,
                          message: "Please confirm your password!"
                        },
                        {
                          validator: this.compareToFirstPassword
                        }
                      ]
                    })(
                      <Input
                        placeholder="Retype New Password"
                        type="password"
                        readOnly={disabled}
                        onFocus={this.handleOnFocus}
                        onBlur={this.handleConfirmBlur}
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    <Button loading={this.state.loading} type="primary" htmlType="submit">
                      <IntlMessages id="app.userAuth.reset" />
                    </Button>
                  </FormItem>
                </Form>
              </div>  
          </div>
        </div>
      );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default WrappedResetPasswordForm;
