import React from 'react';
import { NavLink } from 'react-router-dom';
import './OnboardingPage.css';
const OnboardingPage = () => {
  return (
    <div
      className="flex flex-col items-center justify-start  min-h-[calc(100vh-64px)] w-full"
      style={{
        backgroundImage: 'url("/clouds-sky.png")',
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        position: 'relative'
      }}
    >
      <div
        className="absolute inset-0 bg-white/80"
        style={{ backdropFilter: 'blur(2px)' }}
      ></div>
      <div className="flex flex-col items-center justify-center pt-[100px] w-full gap-2 p-6 relative z-10">
        <div className="flex items-center gap-3 mb-2">

          <h2 className="text-3xl font-semibold text-center flex items-center justify-center gap-2">
            Welcome to <img src="/ic-logo-2.png" alt="iCompaas" className="h-10 inline-block mx-1" /> iCompaas Onboarding
          </h2>
        </div>
        <p className="text-gray-600 text-lg text-center">Your Complete Cloud Security & Compliance Platform</p>
        <div className="flex flex-col items-center justify-center w-full gap-6">
          <div className="flex items-center gap-6">
            <button className="inline-flex items-center justify-center h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm w-64 transform hover:scale-105 hover:shadow-lg group">
              <svg className="w-6 h-6 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
              </svg>
              Cloud Onboarding
            </button>

            <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>

            <NavLink to="/account/cloudaccountsetup?type=aws"
              className="inline-flex items-center justify-center h-12 px-6 bg-[#FF9900] text-white rounded-lg shadow-sm w-48 hover:bg-[#ec8f00] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
              <img src="/aws-on-b-2.svg" className="w-10 h-10" alt="AWS icon" />
            </NavLink>

            <NavLink to="/account/cloudaccountsetup?type=azure"
              className="inline-flex items-center justify-center h-12 px-6 bg-[#0078D4] text-white rounded-lg shadow-sm w-48 hover:bg-[#006cbd] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
              <img src="/azure-on-b.svg" className="w-7 h-7 mr-1" alt="Azure icon" />
              Azure
            </NavLink>

            <div className="relative w-64">
              <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm w-48 cursor-not-allowed transition-all duration-300 hover:opacity-80">
                <img src="/gcp-on-b.svg" className="w-7 h-7 mr-2" alt="GCP icon" />
                <span>Google Cloud</span>
              </button>
              <span className="absolute right-[66px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                Coming Soon
              </span>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <button className="inline-flex items-center justify-center cursor-text h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm w-64 transform hover:scale-105 hover:shadow-lg">
              <svg className="w-5 h-5 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              Personnel Onboarding
            </button>

            <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>

            <div className="relative">
              <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm cursor-not-allowed transition-all duration-300 hover:opacity-80">
                <img src="/google.svg" className="w-6 h-6 mr-2" alt="Google Workspace icon" />
                <span>Google Workspace</span>
              </button>
              <span className="absolute right-[8px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                Coming Soon
              </span>
            </div>

            <div className="relative">
              <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm w-48 cursor-not-allowed transition-all duration-300 hover:opacity-80">
                <img src="/microsoft.svg" className="w-6 h-6 mr-2" alt="Microsoft 365 icon" />
                <span>Microsoft 365</span>
              </button>
              <span className="absolute right-[8px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                Coming Soon
              </span>
            </div>

            <div className="relative w-60">
              <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm cursor-not-allowed transition-all duration-300 hover:opacity-80">
                <img src="/zoho.svg" className="w-6 h-6 mr-2" alt="Zoho icon" />
                <span>Zoho Workplace</span>
              </button>
              <span className="absolute right-[66px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                Coming Soon
              </span>
            </div>
          </div>
          {/* <div className="flex flex-col items-center justify-center w-full gap-6">
            <div className="flex items-center gap-6">
              <button className="inline-flex items-center justify-center h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm  transform hover:scale-105 hover:shadow-lg group">
                <svg className="w-6 h-6 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                </svg>
                CodeRepo Onboarding
              </button>

              <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>

              <div className="relative">
                <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm w-48 cursor-not-allowed transition-all duration-300 hover:opacity-80">
                  <img src="/bitbucket.svg" className="w-7 h-7 mr-2" alt="Bitbucket icon" />
                  <span>Bitbucket</span>
                </button>
                <span className="absolute right-[66px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                  Coming Soon
                </span>
              </div>

              <div className="relative">
                <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm w-48 cursor-not-allowed transition-all duration-300 hover:opacity-80">
                  <img src="/github.svg" className="w-7 h-7 mr-2" alt="GitHub icon" />
                  <span>GitHub</span>
                </button>
                <span className="absolute right-[66px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                  Coming Soon
                </span>
              </div>
            </div>
          </div> */}
        </div>

        <div className="flex items-center justify-center w-full p-6 bg-blue-50 text-blue-700 rounded-lg mt-6">
          <div className="text-center">
            <p className="mb-4 text-sm">No compliance modules are currently active.</p>
            <NavLink
              to="/account/addons"
              className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors shadow-sm"
            >
              <span className="text-sm font-medium">Explore Available Modules</span>
              <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;